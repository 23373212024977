.button {
  position: fixed;
  padding: 0;
  right: 0%;
  bottom: 40px;
  border-radius: 50%;
  border: none;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #43a1d0;
  transition: all 0.5s ease-out;
}

.button:hover {
  color: #3e4b94;
}
