@font-face {
    font-family: "AAvanteLt";
    src: url(./AAvanteLtLight.woff) format("woff");
    font-weight: 200;
    font-display: swap;
  }
  
  @font-face {
    font-family: "AAvanteLt";
    src: url(./a-avantelt-demibold.woff) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
