.nav {
  display: flex;
  width: 100%;
  min-height: 66px;
  background-color: #43a1d0;
  grid-column-start: 1;
  grid-column-end: 5;
  position: relative;
  background: linear-gradient(284deg, #3e4b94, #43a1d0);
  box-shadow: 0 3px 22px 0 rgb(0 0 0 / 25%);
  transition: all 0.5s ease-out;
  color: #fff;
}

.nav__list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding: 20px 0;
  transition: top 0.3s;
}

.nav__close {
  display: none;
  border: none;
  padding: 0;
  background-image: url(../../image/close.svg);
  background-size: cover;
  width: 40px;
  height: 40px;
  background-color: transparent;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.nav__list-item {
  text-decoration: none;
  list-style: none;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-left: 40px;
  cursor: pointer;
  margin-left: 20px;
  transition: all 0.5s ease-out;
}

.nav__list-item:first-child {
  margin: 0;
}

.nav__list-item:hover {
  text-decoration: underline;
}

.nav__menu {
  display: none;
  position: absolute;
  right: 18px;
  top: 18px;
}

.nav__menu_hide {
  display: none;
}

.nav__menu-line {
  display: none;
  margin-bottom: 9px;
  cursor: pointer;
  border-bottom: 4px solid #000000;
  width: 33px;
}

@media screen and (max-width: 1000px) {
  .nav {
  }

  .nav__menu {
    background-color: transparent;
    border: none;
    display: block;
  }
  .nav__menu_hide {
    display: none;
  }

  .nav__list {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .nav__list_hide {
    display: none;
  }

  .nav__list-item {
    padding: 20px;
    font-size: 25px;
    margin: 0;
  }

  .nav__menu-item:first-child {
    margin: 0;
  }
  .nav__menu-line {
    display: block;
  }
  .nav__close {
    display: block;
  }
}
