.header {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  padding: 20px 20px 0 20px;
  gap: 40px;
  align-items: center;
}

.header__logo {
  max-width: 142px;
  min-height: 33px;
}

.header__items {
  list-style: none;
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.header__item {
  text-decoration: none;
  color: #000000;
  display: flex;
  align-items: center;
}

.header__item_call {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.header__item_call-link {
  text-decoration: none;
  color: #000000;
}

.header__btn {
  border-radius: 24px;
  text-decoration: none;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
  white-space: nowrap;
  max-width: 150px;
  background: linear-gradient(284deg, #3e4b94, #43a1d0);
  box-shadow: 0 3px 22px 0 rgb(0 0 0 / 25%);
  transition: all 0.5s ease-out;
}

.header__btn:hover {
  background: linear-gradient(32deg, #3e4b94, #43a1d0);
}

@media screen and (max-width: 1000px) {
  .header {
    gap: 10px;
  }
}

@media screen and (max-width: 796px) {
  .header {
    grid-template-columns: auto 1fr auto;
    padding: 20px 20px 0 20px;
    gap: 10px;
    justify-items: center;
  }

  .header__items {
    padding: 0;
  }


  .header__item_call {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .header__btn {
    max-width: 110px;
    font-size: 14px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 540px) {
  .header {
    grid-template-columns: 1fr auto;
    padding: 10px;
  }

  .header__items_adress {
    display: none;
  }

  .header__logo {
    max-width: 130px;
  }

  @media screen and (max-width: 440px) {
    .header__items {
    font-size: 16px;
    }
  }
}
