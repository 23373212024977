.about {
  position: relative;
}
.about__img {
  background-image: url(../../image/pic9.webp);
  min-height: 400px;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
}
