.popup {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.8s;
  justify-content: center;
  align-items: center;
  font-family: "Inter", "Arial", "sans-serif";
  z-index: 2;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  width: 100%;
  max-width: 430px;
  max-height: 100vh;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__text {
    margin:5px auto;
    text-align: center;
}

.popup__close {
  border: none;
  padding: 0;
  background-image: url(../../image/close.svg);
  background-size: cover;
  width: 32px;
  height: 32px;
  background-color: transparent;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: -40px;
}

.popup__close:hover {
  opacity: 0.6;
}

.popup__logo {
    max-width: 130px;
    margin-top: 10px;
}

@media screen and (max-width: 736px) {
    .popup__close {
      width: 20px;
      height: 20px;
      right: 0;
      top: -36px;
    }
  }
