.price__list {
  padding: 0 40px;
}

.price__img {
  max-width: 50px;
  margin-top: 14px;

}

/* .price__img:first-child {
  background-image: url(../../image/icon6.svg);
  background-size: contain;
  background-position: center;
}

.price__img:first-child:hover {
  background-color: #ffffff;
  background-image: url(../../image/tooth_10299303.svg);
  background-size: contain;
  background-position: center;
} */

.price__list-item {
  list-style: none;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: solid 1px gray;
}

.price__value {
  margin: 0;
  white-space: nowrap;
}

.price__name {
  margin: 0;
}
.price__name:first-of-type {
  margin: 020px;
}

@media screen and (max-width: 1000px) {
  .price__list {
    padding: 0 20px;
  }
}
@media screen and (max-width: 500px) {
  .price__list-item {
    font-size: 14px;
  }
  .price__img {
    max-width: 30px;
    margin-right: 1px;
  }
}
