.footer {
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  padding: 40px 20px 0 20px;
  gap: 40px;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .footer {
    grid-template-columns: auto auto;
    padding: 20px;
    gap: 10px;
  }
}

@media screen and (max-width: 440px) {
  .footer {
    padding: 10px;
    gap: 8px;
    justify-items: center;
  }
}
