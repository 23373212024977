
@import url(./vendor/fonts/fonts.css);

.body {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.page {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  background-color: #f8f9fa;
}

/* #43a1d0 #3e4b94*/
.content {
  display: flex;

  width: 100%;
  max-width: 1280px;
  flex-direction: column;
}

.content__section {
  padding: 30px 0;
}

.section__title {
  color: #3e4b94;
  font-weight: 700;
  font-size: 54px;
  line-height: 58px;
  margin: 0 0 0 40px;
  margin-bottom: 30px;
}

.section__title_about {
  position: absolute;
  top: 70px;
  left: 30px;
}

.section__subtitle {
  color: #3e4b94;
  position: absolute;
  top: 120px;
  left: 30px;
}

.section__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 163%;
  padding: 20px 20px 0 80px;
  max-width: 690px;
  margin: 0;
}

.layout__body {
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .section__text {
    padding: 20px 20px 0 20px;
    margin: 0;
  }
}
